import axios from '@/helpers/axios';
import type { Hydra, HydraMember } from '@/types/HydraTypes';
import type { Venue } from '@/types/Venue';

class VenueDataService {
  async getAll(): Promise<HydraMember<Venue>> {
    return axios.get<HydraMember<Venue>>('/venues')
      .then(response => response.data);
  }

  async get(id: any): Promise<Venue> {
    return axios.get<Venue & Hydra>(`/venues/${id}`)
      .then(response => response.data);
  }

  async create(data: any): Promise<Venue & Hydra> {
    return axios.post<Venue & Hydra>('/venues', data)
      .then(response => response.data);
  }

  async update(id: any, data: Partial<Venue>): Promise<any> {
    return axios.patch<Venue & Hydra>(`/venues/${id}`, data);
  }

  async delete(id: any): Promise<any> {
    return axios.delete(`/venues/${id}`);
  }
}

export default new VenueDataService();
