import type { AxiosError, AxiosResponse } from 'axios';
import type { LoginResponse } from '@/types/auth/LoginResponse';
import axios from '@/helpers/axios';

class AuthService {
  login(username: string, password: string): Promise<AxiosResponse> {
    return axios.post('/login', {
      username,
      password,
    })
      .catch((response: AxiosResponse) => {
        return response;
      })
      .then((response: AxiosResponse) => {
        return response;
      });
  }

  refreshToken(token: string): Promise<LoginResponse> {
    return axios.post('/token/refresh', {
      refresh_token: token,
    }).then((response: AxiosResponse) => {
      return response.data;
    });
  }

  resetPassword(email: string): Promise<AxiosResponse> {
    return axios.post('/security/passwords/request', {
      email,
    })
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }

  setPassword(token: string, password: string): Promise<AxiosResponse> {
    return axios.post('/security/passwords/restore',
      {
        token,
        password,
      },
      {
        validateStatus(status) {
          return status < 400; // Resolve only if the status code is less than 500
        },
      },
    ).then((response: AxiosResponse, error: AxiosError) => {
      return true;
    });
  }

  logout() {

  }
}

export default new AuthService();
