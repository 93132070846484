import { useUserStore } from '@/stores/user';
import baseaxios from 'axios';
import { useToast } from 'vue-toastification';

const axios = baseaxios.create({
  baseURL: `${import.meta.env.VITE_API_URL}/api`,
  headers: {
    'Content-Type': 'application/ld+json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
  },
});

const publicPages = ['/login', '/security/passwords/restore', '/security/passwords/request', '/verify-email', '/token/refresh'];

axios.interceptors.request.use(config => {
  const token = localStorage.getItem('authorization');
  const userStore = useUserStore();

  if (token && !publicPages.includes(config.url)) {
    config.headers = config.headers || {};
    config.headers.Authorization = token ? `Bearer ${token}` : '';
  }
  if (userStore.currentVenue !== null)
    config.headers['X-Venue'] = userStore.currentVenue.id;

  if (config.method === 'patch')
    config.headers['Content-Type'] = 'application/merge-patch+json';

  return config;
});

axios.interceptors.response.use(
  response => response,
  async error => {
    const userStore = useUserStore();
    const toast = useToast();
    if (error.response.status === 401 && error.config.url !== '/token/refresh') {
      const newToken = await userStore.loadTokenByRefreshToken();

      error.config.headers.Authorization = `Bearer ${newToken}`;

      return baseaxios.request(error.config);
    } else if (error.response.status === 401 && error.config.url === '/token/refresh') {
      userStore.logout();
    } else {
      let data = error.response.data;

      if (error.request.responseType === 'blob' && data instanceof Blob && data.type) {
        const text = await data.text();
        data = data.type.toLowerCase().includes('json') ? JSON.parse(text) : text;
      }

      toast.error(data.detail);

      return Promise.reject(error);
    }
  });

export default axios;
